$circle-width: 0.15em;
$circle-width-hover: 0.1em;
// colors default
$primary-color: #307bbb;
$secondary-color: #ffffff;
$bg-color: #f5f5f5;
// colors blue border
$primary-color-blue: #285E62;
$bg-color-blue: #6ABCB7;
// color l-blue border
$primary-color-l-blue: #6ABCB7;
$bg-color-l-blue: #285E62;

$primary-color-grey: #6ABCB7;
$bg-color-grey: #f7f7f7