// VARS
@import "_variables";
// mixins
@import "_mixins";
// CIRCLE
// classes 2 extend
.rect-auto {
    clip: rect(auto, auto, auto, auto);
}

.pie {
    position: absolute;
    border: $circle-width solid $primary-color;
    width: 1 - (2 * $circle-width);
    height: 1 - (2 * $circle-width);
    clip: rect(0em, 0.5em, 1em, 0em);
    border-radius: 50%;
    @include rotate(0deg);
}

.pie-fill {
    @include rotate(180deg);
}

// main
.c100 {
    *,
    *:before,
    *:after {
        @include box-sizing(content-box);
    }
    position: relative;
    font-size: 95px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    float: left;
    margin: 0 0.1em 0.1em 0;
    // background-color: $secondary-color;
    // center circle to its parent
    &.blue {
        background-color: $secondary-color;
    }

    &.l-blue {
        background-color: $secondary-color;
    }

    &.grey-l-blue {
        background-color: #D9D9D9;
    }
    &.grey-blue {
        background-color: #D9D9D9;
    }

    &.center {
        float: none;
        margin: 0 auto;
    }
    // bigger size
    &.big {
        font-size: 240px;
    }
    // smaller size
    &.small {
        font-size: 80px;
    }
    // centered value inside circle
    >span {
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        width: 5em;
        line-height: 5em;
        font-size: 0.2em;
        color: $secondary-color;
        display: block;
        text-align: center;
        white-space: nowrap;
        @include transition-property(all);
        @include transition-duration(0.2s);
        @include transition-timing-function(ease-out);
    }
    // background inside the circle
    &:after {
        position: absolute;
        top: $circle-width;
        left: $circle-width;
        display: block;
        content: " ";
        border-radius: 50%;
        background-color: $bg-color;
        width: 1 - (2 * $circle-width);
        height: 1 - (2 * $circle-width);
        @include transition-property(all);
        @include transition-duration(0.2s);
        @include transition-timing-function(ease-in);
    }
    // the slice (mask)
    .slice {
        position: absolute;
        width: 1em;
        height: 1em;
        clip: rect(0em, 1em, 1em, 0.5em);
    }
    // circle to show the status
    .bar {
        @extend .pie;
    }
    // loop to create all needed elements automatically
    @for $i from 51 through 100 {
        &.p#{$i} {
            & .slice {
                @extend .rect-auto;
            }
            &.bar:after {
                @extend .pie-fill;
            }
            & .fill {
                @extend .pie;
                @extend .pie-fill;
            }
        }
    }
    // loop to rotate all 100 circles
    @for $i from 1 through 100 {
        &.p#{$i} .bar {
            $degs: (calc(360/100) * $i);
            @include rotate(#{$degs}deg);
        }
    }
    &.blue {
        .bar,
        .fill {
            border-color: $primary-color-blue !important;
        }
        &:after {
            background-color: $bg-color-blue;
        }
    }
    &.l-blue {
        .bar,
        .fill {
            border-color: $primary-color-l-blue !important;
        }
        &:after {
            background-color: $bg-color-l-blue;
        }
    }
    &.grey-l-blue {
        .bar,
        .fill {
            border-color: $primary-color-grey !important;
        }
        &:after {
            background-color: $bg-color-grey;
        }
    }
    &.grey-blue {
        .bar,
        .fill {
            border-color: $primary-color-blue !important;
        }
        &:after {
            background-color: $bg-color-grey;
        }
    }
}