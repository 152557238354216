/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Tailwind files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "assets/percentage-circle/scss/circle.scss";

@font-face {
  font-family: 'Raleway'; //This is what we are going to call
  src: url('./assets/fonts/Raleway-Regular.ttf');
}

@font-face {
  font-family: 'Rubik'; //This is what we are going to call
  src: url('./assets/fonts/Rubik-SemiBold.ttf');
}

* {
  font-family: 'Raleway', sans-serif !important;
  --color: #616161;
}

.top {
  padding: 45px;
  .title {
    color: #343741;
    font-size: 30px;
    letter-spacing: 2px;

    span {
      font-family: 'Rubik' !important;
    }
  }
}

ion-content {
  --ion-background-color: #F6F6F6;
}

ion-card {
  overflow: visible;
  background-color: #ffffff;
  color: #343741;

  .buttons-absolute-right {
    position: absolute;
    bottom: 25px;
    right: 25px;
  }

  .buttons-absolute-left {
    position: absolute;
    bottom: 25px;
  }

}

ion-card.simple {
  border-radius: 25px;
  padding: 25px;
  text-align: left;
  box-shadow:  0px 4px 4px 0px rgba(0, 0, 0, 0.17);

  h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  p, ul, span {
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 25px;
  }

  p.no-margin {
    margin-top: 0px;
  }

  .buttons-right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

ion-card.genially {
  border-radius: 25px;
  text-align: left;
  box-shadow:  0px 4px 4px 0px rgba(0, 0, 0, 0.17);
}

ion-card.simple-small {
  border-radius: 16px;
  padding: 16px;
  margin-top: 0px;
  text-align: left;
  box-shadow:  0px 4px 4px 0px rgba(0, 0, 0, 0.17);
  min-width: 350px;

  .header {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  h1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  p, ul, span {
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  p.no-margin {
    margin-top: 0px;
  }
}

ion-card.simple-small-p2p {
  border-radius: 16px;
  padding: 16px;
  margin-top: 0px;
  max-width: 390px;
  text-align: left;
  box-shadow:  0px 4px 4px 0px rgba(0, 0, 0, 0.17);
  min-width: 350px;

  .header {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  h1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  p, ul, span {
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  p.no-margin {
    margin-top: 0px;
  }
}

ion-card.full-screen {
  width: 100%;
  margin: 0px;
  height: 100%;
  box-shadow:  0px 0px 0px 0px rgba(0, 0, 0, 0.17);
  background-color: #343741;
  border-radius: 0px;

  ion-card-header {
    background-color: #285E62;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    height: 62px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;

    ion-item::part(native) {
      background: #285E62;
    }

    ion-icon {
      color: #ffffff
    }

    .timer {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      height: 35px;
      background-color: #F2B23E;
      border-radius: 16px;

      ion-icon {
        font-size: 20px;
      }

      &.ending {
        color: red;
        background-color: #fff;

        ion-icon {
          color: red;
        }
      }

      span {
        font-weight: bold;

        &.coutdown {
          position: absolute;
          right: 25px;
        }
      }
    }

  }

  ion-card-content {
    padding: 0px;
    height: 100%;
  }

  .skeleton-simulation-video {
    border-radius: 25px;
    margin: 0;
  }

  .simulations-buttons {
    width: 100%;
    background-color: #285E62;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    min-height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    height: 12%;
  }
}

ion-card.light-background {
  background-color: #ffffff;
}

ion-card.header-color {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.17);
  border-radius: 25px;

  ion-card-header {
    background-color: #285E62;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    max-height: 62px;
    min-height: 52px;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  ion-card-content {
    padding: 25px;
  }

  h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.6px;
  }

  p, ul {
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 25px;
  }

  p.no-margin {
    margin-top: 0px;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 5px;
}

::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #E5E7EB;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

.ion-overlow-hidden {
  --overflow: hidden;
}

.simulations-list-info-modal {
  --height: 352px;
  --max-width: 90%;
}

.wider-popover {
  --width: 315px !important;
}

.reschedule-modal-css {
  --min-width: 600px;
  --min-height: 550px;
}

.availability-modal-css {
  --min-width: 90%px;
  height: auto !important;
}

.background-img {
  position: fixed;
  top: 350px;
  z-index: -100;
}

ion-alert {
  --max-width: 465px !important;
}

ion-menu {
  ion-content {
    --ion-background-color: #323232;

    ion-label {
      --color: #acacac !important;
    }

    ion-item.selected ion-label {
      --color: #fff !important;
    }
  }
}



.description {
  p {
    font-size: 16px !important;
    margin-bottom: 16px;
  }
}

@media (min-width: 992px) {
  ion-menu.notifications {
    --width: 400px;
  }
}

ion-title {
  font-size: 14px;
  color: #343741;

  span:last-child {
    font-weight: bold;
  }
}

ion-button {
  text-transform: none;
  height: 49px;
  font-weight: 600;
  font-size: 15px;
  min-width: 100px;
  --border-radius: 10px;
}

ion-icon {
  pointer-events: none;
}

ion-icon.end-button {
  rotate: 135deg;
}

div.break {
  clear: both;
}

blockquote {
  margin-left: -0.75rem;
  margin-right: 0;
  padding-left: 1.375em;
  border-left: 0.375em solid #7B8898;
  color: #878b91;
  font-size: 14px;
  font-size: 0.875em;
  font-weight: 400;
  font-style: italic;
  font-family: "Mercury SSm A", "Mercury SSm B", Georgia, Times, "Times New Roman", "Microsoft YaHei New", "Microsoft Yahei", "微软雅黑", 宋体, SimSun, STXihei, "华文细黑", serif;
  line-height: 1.5;
}

.popup-message {
  left: initial;
  right: 18px;
  bottom: 26px;
  padding: 16px;
  font-size: 16px;
  max-width: 200px;
  margin-bottom: 100px;
  --color: #404040;
  --background: rgba(243, 178, 60, 0.5);
}

.popup-message-slide {
  top: 52px;
  padding: 16px;
  font-size: 16px;
  --color: #404040;
  --background: rgba(243, 178, 60, 0.5);
  --width: 250px;
  --start: 1;
}

ion-popover.popup-completed-simulations {
  --backdrop-opacity: 0.4;
  --width: 380px;
  --offset-y: -35px;
}

ion-popover.popup-completed-simulations ion-content {
  --background: #fff;
  --color: #343741;
}


ion-popover.popup-completed-simulations::part(backdrop) {
  background-color: rgba(0, 0, 0, 0.5);
}

ion-popover.popup-completed-simulations::part(arrow) {
  --background: #fff;
  --offset-y: 1px;
  transform: rotate(0deg) !important;
}

.popup-error-slide {
  padding: 16px;
  font-size: 16px;
  --color: #000;
  --background: rgba(360, 75, 100, 0.5);
  --width: 250px;
  --start: 1;
}

.popup-update-user-slide {
  font-size: 16px;
  --color: #404040;
  --background: rgba(243, 178, 60, 0.5);
  --width: 300px;
  --start: 2;
}

.popup-slide {
  font-size: 16px;
  --color: #404040;
  --background: rgba(243, 178, 60, 1);
  --width: 300px;
  --start: 2;
}

.popup-reschedule {
  font-size: 16px;
  --color: #404040;
  --background: rgba(243, 178, 60, 1);
  --width: 400px;
  --start: 2;
}

.popup-avatar-simulation {
  padding: 26px;
  font-size: 16px;
  --color: #000;
  --background: rgba(243, 178, 60, 1);
  --width: 350px;
  --start: 1;
}

ion-popover.p2p-popover-steps {
  --background: grey;
  --backdrop-opacity: 0.6;
  --box-shadow: 10px 14px 16px -12px rgba(0, 0, 0, 0.4)
  --color: white;
  --width: 400px;
  --offset-y: -240px;
  border: none;
  --padding-bottom: 10px;
}

ion-popover.p2p-popover-steps ion-content {
  --background: #fff;
  --color: 343741;
  --padding-bottom: 10px;
  border: none !important;
}

ion-popover.p2p-popover-steps::part(backdrop) {
  background-color: rgba(0, 0, 0, 0.5);
}

ion-popover.p2p-popover-steps::part(arrow) {
  --background: #fff;
  --offset-y: 1px;
  transform: rotate(0deg) !important;
}

ion-popover.p2p-popover {
  --background: grey;
  --backdrop-opacity: 0.6;
  --box-shadow: 10px 14px 16px -12px rgba(0, 0, 0, 0.4)
  --color: white;
  --width: 400px;
  --offset-y: -97px;
  border: none;
  --padding-bottom: 10px;
}

ion-popover.p2p-popover ion-content {
  --background: #fff;
  --color: 343741;
  --padding-bottom: 10px;
  border: none !important;
}

ion-popover.p2p-popover::part(backdrop) {
  background-color: rgba(0, 0, 0, 0.5);
}

ion-popover.p2p-popover::part(arrow) {
  --background: #fff;
  --offset-y: 1px;
  transform: rotate(0deg) !important;
}

ion-popover.popover-help-tips {
  --backdrop-opacity: 0.6;
  --color: white;
  --width: 625px;
}

ion-popover.popover-help-tips ion-content {
  --background: #fff;
  --color: 343741;
}

ion-popover.popover-help-tips::part(backdrop) {
  background-color: rgba(0, 0, 0, 0.5);
}

ion-popover.popover-help-tips::part(arrow) {
  --background: #fff;
  --offset-y: 1px;
  transform: rotate(0deg) !important;
}
ion-popover.popover-help-tips::part(arrow)::after {
  top: 4px;
}

ion-popover.popover-help {
  --background: grey;
  --backdrop-opacity: 0.6;
  --box-shadow: 10px 14px 16px -12px rgba(0, 0, 0, 0.4)
  --color: white;
  --width: 380px;
  --offset-y: -83px;
  border: none;
  --padding-bottom: 10px;
}

ion-popover.popover-help-audio {
  --offset-y: -58px;
}

ion-popover.popover-help-big {
  --offset-y: -132px;
  --offset-x: 8px;
}

ion-popover.popover-help ion-content {
  --background: #fff;
  --color: 343741;
  --padding-bottom: 10px;
  border: none !important;
  --overflow: hidden;
}

ion-popover.popover-help::part(backdrop) {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
}

ion-popover.popover-help::part(arrow) {
  --background: #fff;
  --offset-y: 1px;
  transform: rotate(0deg) !important;
}

ion-popover.popover-help .ok-button {
  float: right;
  height: 30px;
  margin-top: 16px;
  min-width: 58px;
}

ion-popover.popover-tips {
  --background: grey;
  --backdrop-opacity: 0.6;
  --box-shadow: 10px 14px 16px -12px rgba(0, 0, 0, 0.4)
  --color: white;
  --width: 380px;
  --offset-y: -25px;
  border: none;
  --padding-bottom: 10px;
}

ion-popover.popover-tips ion-content {
  --background: #fff;
  --color: 343741;
}

ion-popover.popover-tips::part(backdrop) {
  background-color: rgba(0, 0, 0, 0.5);
}

ion-popover.popover-tips::part(arrow) {
  --background: #fff;
}

ion-popover.popover-tips .ok-button {
  float: right;
  height: 30px;
  margin-top: 26px;
  min-width: 58px;
}

.alert-wrapper {
  text-align: center;

  .alert-head {
    text-align: center;
  }

  .alert-button.sc-ion-alert-md {
    margin-inline-end: 0;
  }

  .alert-button-inner.sc-ion-alert-md {
    justify-content: space-around;
    text-transform: none;
    font-weight: bold;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.2px;
  }
}

// Video guide
#dragable {
  z-index: 9999;
  width: 200px;
  height: 200px;
  border-radius: 125px;
  // border: 0.2rem solid #0f2c65;
  position: absolute;
  bottom: 50px;
  left: 23px;
  text-align: center;
  z-index: 999;
  box-shadow: 2px 4px 18px rgba(0, 0, 0, 0.2);
  transition: border-color 0.2s, box-shadow 0.2s;
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

#dragable #p2p_guide {
  width: 448px;
  height: 478px;
  position: absolute;
  top: -125px;
  left: -125px;
  cursor: move;
  max-width: fit-content;
}

#dragable.drag {
  border-color: white;
  box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.5);
}

.video-circle {
  width: 150px;
  height: 150px;
  border-radius: 125px;
  position: relative;
  z-index: 999;
  box-shadow: 2px 4px 18px rgb(0 0 0 / 20%);
  transition: border-color 0.2s, box-shadow 0.2s;
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

  video {
    width: 500px;
    height: 500px;
    position: absolute;
    top: -125px;
    left: -125px;
    max-width: fit-content;
  }
}


.cpitalize {
  text-transform: capitalize;
}

ion-list.clear {
  background-color: transparent !important;

  ion-list-header {
    text-align: left;
    font-size: 16px;
    padding-bottom: 20px;
  }

  ion-item {
    --background: transparent;

    ion-label {
      white-space: initial !important;
    }
  }
}

div.slide-content {
  margin-bottom: 85px;
  text-align: left;

  // h2 {
  //   font-size: 23px;
  //   padding-bottom: 10px;
  // }

  h4 {
    font-size: 21px;
    padding-bottom: 10px;
  }

  p {
    font-size: 17px;
    padding-bottom: 10px;
  }

  blockquote {
    margin-left: 0.25rem;
    border-left: 0.375em solid #ccc;
  }

  img {
    float: right;
    // max-width: 400px;
  }
}

ion-modal {
  --border-radius: 25px !important;
}

.time-is-up-modal {
  --height: 255px
}

.card-modal {
  --border-radius: 24px !important;
  --width: 700px;
}

.guide-modal {
  --border-radius: 5px !important;
  --width: 700px;
  padding: 20px;
}

.last-guide-modal {
  --border-radius: 5px !important;
  --width: 500px;
  --height: auto;
}

.help-modal {
  --border-radius: 5px !important;
  --width: 330px;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 26px;

  ion-content {
    --ion-background-color: initial !important;
    max-height: 200px;
    height: 200px;
  }

}

ion-modal.help-modal,
ion-modal.guide-modal {
  --height: auto;
}
ion-modal.help-modal .ion-page,
ion-modal.guide-modal .ion-page {
  position: relative;
  display: block;
  contain: content;
}
ion-modal.help-modal .ion-page .inner-content,
ion-modal.guide-modal .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
}

.guided-visit-popover {
  --width: 500px;
}

.guided-visit-demo-modal-user-demo {
  --height: 220px;
}

.guided-visit-demo-modal {
  --height: 200px;
}

.guided-visit-demo-modal-expired {
  --height: 175px;
}

.help-popover {
  --width: 270px;
}

ion-modal.card-modal {
  --height: auto;
}
ion-modal.card-modal .ion-page {
  position: relative;
  display: block;
  contain: content;
}
ion-modal.card-modal .ion-page .inner-content {
  max-height: 80vh;
  overflow: auto;
}

.custom-profile-simulation-alert {
  .alert-wrapper {
    text-align: left;
    padding: 16px;
    .alert-message.sc-ion-alert-md {
      font-size: 17px !important;
      max-height: 800px;
    }
  }
}

@media (max-width: 600px) {

  .simulations-list-info-modal {
    --height: 420px;
  }

  ion-popover.popup-completed-simulations {
    --width: 340px;
  }

  .card-modal {
    padding: 20px;
  }
  .custom-alert {
    --width: 320px;
    --max-width: 90%;
  }

  ion-popover.popover-help {
    --width: 90%;
    --offset-y: -151px;
  }

  ion-popover.popover-help-big  {
    --offset-y: -175px;
    --offset-x: 8px;
  }

  ion-card.header-color {
    ion-card-header {
      padding: 15px;
      font-size: 16px;
      max-height: 52px;
      min-height: 42px;
    }
  }
  .top {
    padding: 25px;
  }

  ion-card.full-screen .simulations-buttons {
    height: 80px;
  }

  .guided-visit-popover {
    --width: 75%;
  }

  .last-guide-modal {
    --width: 90%;
  }
}

/* Evaluation page */
ion-card-content,ion-card .content {
  padding: 20px;

  h3 {
    font-size: 17px !important;
    margin-bottom: 20px !important;
    font-weight: 600 !important;
    padding: 22px 30px 0px 20px !important;
  }

  .evaluation {
    text-align: left;
    font-size: 16px;

    div, p {
      font-size: 16px !important;
    }

    p {
      margin: 40px 22px 0;
    }

    ol {
      list-style: circle;
      margin: 0 36px;
      padding: 0;

      li {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: auto;
      margin: 0 36px;
      padding: 0;

      li {
        margin-bottom: 10px;
      }
    }
  }
}

.d-none {
  display: none !important;
}

.skeleton-video {
  min-height: 400px;
  border-radius: 6px;
  background-color: #285E62;
}

.primary-alert-button {
  background-color: #F2B23E!important;
  border-radius: 10px!important;
  color: #616161!important;
}
ion-content{
  --keyboard-offset: 0 !important;
}

.rotate-genially {
  @media (max-width: 600px) {
    transform: rotate(90deg);
  }
}

//// Forum ////
.forum-post-modal {
  --width: 100%;
  --height: 100%;
  padding-left: 30%;
  --border-radius: 0 !important;

  .modal-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 100%;
    transition: right 1s ease-in-out;
    background-color: #fff;
    color: #444;
    overflow-y: auto;

    .modal-content {
      padding: 16px;
    }
  }
}

@keyframes slide-in-right {
  0% {
    right: 100%;
  }
  100% {
    right: 0;
  }
}

.forum-post-modal .modal-wrapper {
  animation: slide-in-right 1s ease-in-out;
}

//// Chat ////
.chat-modal {
  --width: 500px;
  --height: 100%;
  --border-radius: 0 !important;
  align-items: flex-end;

  display: flex;
  flex-direction: column;
  height: 100%;

  &.modal-wrapper {
    transition: transform 1s ease-out;
  }
  &[aria-hidden="false"] {
    transform: translateX(0);
  }
}

//// Notifications ////
.notifications-modal {
  --width: 500px;
  --height: 100%;
  --border-radius: 0 !important;
  align-items: flex-end;

  display: flex;
  flex-direction: column;
  height: 100%;

  &.modal-wrapper {
    transition: transform 1s ease-out;
  }
  &[aria-hidden="false"] {
    transform: translateX(0);
  }
}

.show-more {
  cursor: pointer;
  color: #F2B23E;
  font-weight: bold;
}

.angular-editor .fa {
  font-family: 'FontAwesome' !important;
}

.angular-editor-toolbar .fa {
  font-family: 'FontAwesome' !important;
}
